
export interface Typography {
    fontWeights: FontWeights;
    fontSizes: FontSizes;
}

export interface FontWeights {
}
export interface FontSizes {
}

export const typography: Typography = {
    fontWeights: {},
    fontSizes: {},
}

export const fontWeights: FontWeights = {
}

export const fontSizes: FontSizes = {
}